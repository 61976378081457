import InfiniteScroll from "react-infinite-scroll-component"
import React, { useContext, useEffect, useState } from "react"

import CaseStudyPost from "../../components/CaseStudyPost"
import Container from "../../components/UI/Container"
import DropdownCaseStudies from "../DropdownCaseStudies"
import TitleSection from "../TitleSection"
import SectionWrapper, { ContentWrapper } from "./caseStudiesPostsList.style"
import { VisibilityContext } from "../../contexts/VisibilityContext"

const CaseStudiesPostsList = ({ content, home }) => {
  const context = useContext(VisibilityContext)
  const [fetchPosts, setFetchPosts] = useState(content.slice(0, 6))
  const [step, setStep] = useState(6)

  useEffect(() => {
    if (fetchPosts.length !== content.length) {
      context.setVisible(false)
    }
  })

  const fetchMoreData = () => {
    setFetchPosts(fetchPosts.concat(content.slice(step, step + 6)))
    setStep(step + 6)
    if (fetchPosts.length === content.length) {
      context.setVisible(true)
    }
  }

  return (
    <SectionWrapper>
      <Container width="1220px">
        <TitleSection titleA="All" titleB="Cases" />
        <ContentWrapper>
          {!home && <DropdownCaseStudies />}
          <InfiniteScroll
            dataLength={fetchPosts.length}
            next={fetchMoreData}
            hasMore={true}
          >
            <div className="infinite">
              {fetchPosts.map(blogPost => {
                const {
                  image,
                  title,

                  publication_date,
                } = blogPost.node.data
                return (
                  <CaseStudyPost
                    slug={blogPost.node.uid}
                    date={
                      publication_date || blogPost.node.first_publication_date
                    }
                    tags={blogPost.node.tags}
                    key={blogPost.node.id}
                    thumbUrl={image.localFile?.childImageSharp.fluid}
                    title={title.text}
                  />
                )
              })}
            </div>
          </InfiniteScroll>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default CaseStudiesPostsList

import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import DropdownCaseStudy from "../../components/DropdownCaseStudies"
import DropdownWrapper, { DropdownContent } from "./dropdownCaseStudies.style"

const DropdownCaseStudies = () => {
  const dropdown = useStaticQuery(graphql`
    query DropdownDataCaseStudies {
      allPrismicCaseStudiesPost {
        distinct(field: tags)
        edges {
          node {
            tags
            data {
              industries
              technologies
            }
          }
        }
      }
    }
  `)

  const tags = dropdown.allPrismicCaseStudiesPost.distinct
  const industries = dropdown.allPrismicCaseStudiesPost.edges
  const technologies = dropdown.allPrismicCaseStudiesPost.edges

  function extractUniqueIndustries(arr) {
    const industriesSet = new Set()

    arr.forEach(item => {
      const industries = item.node.data.industries
      industriesSet.add(industries)
    })

    return Array.from(industriesSet)
  }

  function extractUniqueTechnologies(arr) {
    const technologiesSet = new Set()

    arr.forEach(item => {
      const technologies = item.node.data.technologies
      technologiesSet.add(technologies)
    })

    return Array.from(technologiesSet)
  }

  return (
    <DropdownWrapper>
      <DropdownContent>
        <DropdownCaseStudy
          className="dropdown"
          placeholder="ALL INDUSTRIES"
          options={extractUniqueIndustries(industries)}
          categories
        />
        <DropdownCaseStudy
          className="dropdown"
          placeholder="TAGS"
          options={tags}
          tags
        />
        <DropdownCaseStudy
          className="dropdown"
          placeholder="TECHNOLOGIES"
          options={extractUniqueTechnologies(technologies)}
          authors
        />
      </DropdownContent>
    </DropdownWrapper>
  )
}

export default DropdownCaseStudies

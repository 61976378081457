import Img from "gatsby-image"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const CaseStudyPost = ({ className, thumbUrl, title, slug }) => {
  // Add all classes to an array
  const addAllClasses = ["blog_post"]

  // className prop checking
  if (className) {
    addAllClasses.push(className)
  }

  return (
    <div className={addAllClasses.join(" ")}>
      <div>
        <Link to={`/case-studies/${slug}/`}>
          <div className="overlay">
            <div className="thumbnail">
              <Img
                fluid={thumbUrl}
                alt={`${title} thumbnail`}
                className="image"
                fadeIn={false}
                loading="lazy"
              />
            </div>
          </div>
          <div className="content">
            <div className="content-intro">
              <p className="case-study">Case Study</p>
              <hr className="seperator" />
            </div>
            <h3 className="title">{title}</h3>
          </div>
        </Link>
      </div>
    </div>
  )
}

CaseStudyPost.propTypes = {
  className: PropTypes.string,
  thumbUrl: PropTypes.object,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  link: PropTypes.element,
  category: PropTypes.string,
  date: PropTypes.string,
  tags: PropTypes.array,
  slug: PropTypes.string,
}

export default CaseStudyPost

import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"

import CloseIcon from "../../common/assets/image/alpacked/home/closeD.svg"
import OpenIcon from "../../common/assets/image/alpacked/home/openD.svg"

const DropdownCaseStudies = ({
  value,
  options,
  placeholder = "Select",
  categories,
  authors,
  tags,
}) => {
  const node = useRef()

  const [open, setOpen] = useState(false)

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [open])

  function replaceSpacesWithUnderscoreAndLowerCase(inputString) {
    // Remove spaces, replace them with underscores, and make the result lowercase
    let outputString = inputString.replace(/ /g, "_").toLowerCase()
    return outputString
  }

  return (
    <div
      ref={node}
      className="dropdown"
      onClick={e => setOpen(!open)}
      aria-hidden="true"
    >
      <button className="dropdown-toggler">{value || placeholder}</button>
      <img
        loading="lazy"
        src={!open ? CloseIcon : OpenIcon}
        alt="dropdown icon"
      />
      {open && (
        <ul
          className={
            categories || authors ? "dropdown-menu" : "dropdown-menu-alt"
          }
        >
          {options.map((opt, index) => (
            <>
              {categories ? (
                <Link
                  to={`/case-studies/industries/${replaceSpacesWithUnderscoreAndLowerCase(
                    opt
                  )}/`}
                  key={index}
                >
                  <li className="dropdown-menu-item" key={index}>
                    {opt}
                  </li>
                </Link>
              ) : tags ? (
                <Link to={`/case-studies/tag/${opt.toLowerCase()}/`}>
                  <li className="dropdown-menu-item" key={index}>
                    {opt}
                  </li>
                </Link>
              ) : (
                <Link
                  to={`/case-studies/technologies/${replaceSpacesWithUnderscoreAndLowerCase(
                    opt
                  )}`}
                >
                  <li className="dropdown-menu-item" key={index}>
                    {opt}
                  </li>
                </Link>
              )}
            </>
          ))}
        </ul>
      )}
    </div>
  )
}

export default DropdownCaseStudies
